import { Button, Icon } from ".";

import { get } from "lodash";
import styled from "styled-components";

const Tags = ({ data }) => {
  const tags = get(data, "value", "")
    .split(",")
    .filter((t) => t !== "");

  const placeholder = get(data, "placeholder", "");

  const finalTags = tags.length > 0 ? tags : [""];

  return (
    <Container orientation={data.orientation || "vertical"}>
      {finalTags.map((t, index) => (
        <Tag key={index} orientation={data.orientation || "vertical"}>
          <TagInput
            value={t}
            placeholder={placeholder}
            onChange={(e) => {
              data.onChange(
                finalTags
                  .map((t, i) => (i === index ? e.target.value : t))
                  .join(",")
              );
            }}
          />
          <Icon
            data={{
              icon: "FiX",
              size: 16,
              color: "var(--light-grey)",
              hover: true,
              onClick: () => {
                data.onChange(
                  finalTags.filter((t, i) => i !== index).join(",")
                );
              },
            }}
          />
        </Tag>
      ))}
      <Button
        data={{
          text: "Add",
          height: "35px",
          icon: "FiPlus",
          type: "hollowWhite",
          border: "1px solid var(--divider)",
          onClick: (e) => {
            data.onChange([...finalTags, " "].join(","));
          },
        }}
      />
    </Container>
  );
};

export default Tags;

const Tag = styled.div`
  background-color: var(--input-background);
  border-radius: 10px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 5px 0 0;
  ${({ orientation }) => orientation === "vertical" && "width: 100%;"}
`;

const TagInput = styled.input`
  border: none;
  background: transparent;
  color: var(--text-color);
  padding: 10px 0 10px 10px;
  outline: none;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  ${({ orientation }) =>
    orientation === "vertical" && "flex-direction: column;"}
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`;
