import "react-notifications-component/dist/theme.css";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { rFetchingUserComplete, rIsFetchingUser, rUser } from "./utils/recoil";

import AdminLogin from "./adminApp/pages/AdminLogin";
import Billing from "./adminApp/billing";
import Browse from "./adminApp/home/Browse";
import { ConfirmationModal } from "./components";
import Cookies from "js-cookie";
import Files from "./adminApp/files";
import History from "./adminApp/home/History";
import Onboarding from "./adminApp/Onboarding";
import PrivateRoute from "./PrivateRoute";
import { ReactNotifications } from "react-notifications-component";
import SessionDetails from "./sessionDetails";
import Settings from "./adminApp/settings";
import Tool from "./tool";
import ToolDetails from "./toolDetails";
import ToolEditor from "./toolConfig/ToolEditor";
import Users from "./adminApp/users";
import { apiRequest } from "./utils/apiRequests";
import { get } from "lodash";
import { getUrlParameter } from "./utils/utils";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

function App() {
  const location = useLocation();

  // useEffect(() => {
  // Dynamically update CSS variable
  // document.documentElement.style.setProperty("--primary", primaryColor);
  // theme === "dark" || isAdminPublicPage
  // document.body.classList.toggle("dark-mode", true);
  // console.log("SET THEME");
  // }, []);

  const navigate = useNavigate();

  const magicLink = getUrlParameter("magic", location);
  const magicRoute = magicLink && getUrlParameter("route", location);

  const [user, setUser] = useRecoilState(rUser);

  const [isFetchingUser, setIsFetchingUser] = useRecoilState(rIsFetchingUser);
  const [fetchingUserComplete, setFetchingUserComplete] = useRecoilState(
    rFetchingUserComplete
  );

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      setIsFetchingUser(true);
      apiRequest.get("/user_details/").then((r) => {
        setUser(get(r, "data"));
        setIsFetchingUser(false);
        setFetchingUserComplete(true);
      });
    } else {
      setFetchingUserComplete(true);
    }
  }, []);

  useEffect(() => {
    if (magicLink) {
      Cookies.set("accessToken", magicLink, { expires: 30 });
      if (magicRoute) {
        navigate(magicRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previewWidth = window.innerWidth;

  return (
    <div>
      <div
        id="modal-portal-root"
        style={{ zIndex: 9999, position: "absolute" }}
      ></div>
      <ReactNotifications style={{ zIndex: 2000 }} />
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route path="tools" element={<Browse />} />
        <Route path="tools/:tab" element={<Browse />} />
        <Route path="history/:toolId" element={<History />} />
        <Route path="history" element={<History />} />
        <Route path="login" element={<AdminLogin type="login" />} />
        <Route path="signup" element={<AdminLogin type="signup" />} />
        <Route path="session/:id" element={<SessionDetails />} />
        <Route path="tool/details/:id" element={<ToolDetails />} />
        <Route path="tool/:id" element={<Tool previewWidth={previewWidth} />} />

        {/* ADMIN ROUTES */}
        <Route path="admin" element={<PrivateRoute />}>
          <Route path="edit_tool/:id" element={<ToolEditor />} />
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="billing" element={<Billing />} />
          <Route path="users" element={<Users />} />
          <Route path="settings" element={<Settings />} />
          <Route path="files" element={<Files />} />
        </Route>

        {/* IF NO DEFAULT PRIVATE OR PUBLIIC PAGES ACCESSIBLE, RENDER LOGIN */}
        <Route path="" element={<Browse />} />
        <Route path="*" element={<Browse />} />
      </Routes>
      <ConfirmationModal />
    </div>
  );
}

export default App;
