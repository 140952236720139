import { EditableText } from "app/adminApp/components";
import { get } from "lodash";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const TitleSection = ({
  title,
  description,
  previewWidth,
  margin,
  onChange,
}) => {
  const location = useLocation();
  const path = get(location, "pathname", "");
  const isEditor = path.includes("edit_tool");
  const titleFontSize = previewWidth < 800 ? 22 : 30;
  const descriptionFontSize = previewWidth < 800 ? 16 : 18;

  return (
    <TitleDescriptionContainer previewWidth={previewWidth} margin={margin}>
      <EditableText
        fontSize={titleFontSize}
        fontWeight={600}
        onChange={(value) => onChange("label", value)}
        value={title}
        editable={isEditor}
        multiline={true}
        placeholder="Your title goes here"
      />
      {(description || isEditor) && (
        <EditableText
          fontSize={descriptionFontSize}
          fontWeight={400}
          onChange={(value) => onChange("description", value)}
          value={description}
          editable={isEditor}
          multiline={true}
          placeholder="Your description goes here"
        />
      )}
    </TitleDescriptionContainer>
  );
};

export default TitleSection;

const TitleDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: ${(p) => p.margin || "50px"};
  ${(p) =>
    p.previewWidth < 800 &&
    `
      margin-bottom: 30px;
  `}
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  align-items: flex-start;
  color: var(--text-color);
  ${(p) =>
    p.previewWidth < 800 &&
    `
    font-size: 22px;
  `}
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  align-items: flex-start;
  color: var(--light-grey);
  ${(p) =>
    p.previewWidth < 800 &&
    `
    font-size: 16px;
  `}
`;
