import { FullScreenLoader, Modal } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEqual } from "lodash";
import {
  rActiveStepIndex,
  rApp,
  rFetchingUserComplete,
  rInput,
  rIsFetchingTool,
  rOutput,
  rOutputStepChanges,
  rRenderCount,
  rUser,
  rWebsocket,
  rWebsocketRequests,
  rWebsocketSessionId,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import useTool, { useFetchTool } from "app/utils/useTool";

import Education from "./Education";
import Input from "./Input";
import Output from "./Output";
import TextareaAutosize from "react-textarea-autosize";
import TitleSection from "./components/TitleSection";
import ToolWrapper from "./ToolWrapper";
import { apiRequest } from "app/utils/apiRequests";
import { getUrlParameter } from "app/utils/utils";
import styled from "styled-components";
import useWebsocket from "app/useWebsocket";

const Tool = ({ previewWidth }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = get(location, "pathname", "");
  const isAdminEditor = pathname.includes("edit");

  const [chatMessage, setChatMessage] = useState("");

  useWebsocket();

  const isFetchingTool = useRecoilValue(rIsFetchingTool);

  useFetchTool();

  const { tool, activeStepConfig, setTool } = useTool();

  const { id } = useParams();

  const [lastInstanceId, setLastInstanceId] = useState(null);

  const [currentStepOutputCopy, setCurrentStepOutputCopy] = useState(null);
  const [isRegenerating, setIsRegenerating] = useState(false);

  const websocketSessionId = useRecoilValue(rWebsocketSessionId);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [regeneratePrompt, setRegeneratePrompt] = useState("");

  const setRenderCount = useSetRecoilState(rRenderCount);

  const app = useRecoilValue(rApp);
  const primaryColor = get(app, "primary_color");

  const steps = get(tool, "steps", []);

  // The index of the current step
  const [currentStepIndex, setCurrentStepIndex] =
    useRecoilState(rActiveStepIndex);

  // Default to first index if none selected
  const activeIndex = currentStepIndex || 0;

  const setActiveStep = (newData) => {
    const newSteps = steps.map((step, index) =>
      index === activeIndex ? { ...step, ...newData } : step
    );
    setTool({
      steps: newSteps,
    });
  };

  // Whether the form is fetching data
  const [isFetching, setIsFetching] = useState(false);

  const currentStepId = get(steps, [activeIndex, "id"], null);

  // The live data in the session, entered by the user and/or generated by AI
  const [output, setOutput] = useRecoilState(rOutput);

  // The initial form input state - not connected to the state once a session is created
  const [input, setInput] = useRecoilState(rInput);

  // The live data in the current step
  const currentStepOutput = get(output, currentStepId, null);

  // The live data in the current step
  const currentStepInput = get(input, currentStepId, null);

  // This handle the various loading states
  const [sessionLoadingState, setSessionLoadingState] = useState(null);

  // A boolean to check if the session has been loaded
  const [sessionLoaded, setSessionLoaded] = useState(false);

  const sessionId = getUrlParameter("sessionId", location);

  const [websocketRequests, setWebsocketRequests] =
    useRecoilState(rWebsocketRequests);

  const websocket = useRecoilValue(rWebsocket);

  const websocketIsOpen = websocket && websocket.readyState === 1;

  const user = useRecoilValue(rUser);

  const fetchingUserComplete = useRecoilValue(rFetchingUserComplete);
  const endpointBase = user ? "get_create_session" : "get_create_session_anon";

  // Fetch the session
  const fetchSession = async () => {
    setSessionLoadingState("fetching");
    const res = await apiRequest.get(`/${endpointBase}/?id=${sessionId}`);
    const session = get(res, "data", {});
    const sessionStepIndex = get(session, "step_index", 0) || 0;
    setCurrentStepIndex(sessionStepIndex);
    setInput(get(session, "input_data", {}));
    setOutput(get(session, "output_data", {}));
    setSessionLoadingState(null);
  };

  // Create a new session
  const createSession = async () => {
    setSessionLoadingState("creating");
    const res = await apiRequest.post(`/${endpointBase}/`, {
      tool_id: id,
    });
    const newSessionId = get(res, ["data", "uuid"]);

    // Replace the current history entry with the new sessionId
    navigate(`${pathname}?sessionId=${newSessionId}`, { replace: true });

    setSessionLoadingState(null);
  };

  // Fetch session
  useEffect(() => {
    if (!isAdminEditor && fetchingUserComplete) {
      // FETCH OR CREATE SESSION IF NOT SET
      if (!sessionLoaded) {
        setSessionLoaded(true);
        if (sessionId) {
          fetchSession();
        } else {
          createSession();
        }
      } else {
        setSessionLoaded(false);
      }
    }
  }, [fetchingUserComplete]);

  const nextStepConfig = get(steps, activeIndex + 1, null);

  const [outputChanges, setOutputChanges] = useRecoilState(rOutputStepChanges);

  const handleNextStep = async () => {
    const isOutputWithChanges = stepType === "output" && outputChanges;
    const nextStepType = get(nextStepConfig, "type", "");

    // If it's an input step, or if it's an output step that was edited, save the changes
    // If the next step is output, don't do this because we'll save them during the AI generation
    if (
      nextStepType !== "output" &&
      (isOutputWithChanges || stepType === "input")
    ) {
      await updateSession(false);
    }

    // HANDLE OUTPUT
    if (nextStepType === "output") {
      // Skip to output (no education or input)
      const nextIndex = regeneratePrompt ? activeIndex : activeIndex + 1;
      runAiAction(nextIndex, regeneratePrompt);
    }

    // HANDLE OTHER STEPS
    else if (["education", "input"].includes(nextStepType)) {
      setCurrentStepIndex(currentStepIndex + 1);
      setSelectedOptions([]);
      setOutputChanges(false);
    }
  };

  // This system handles force updating the markdown in the case of a regeneration step
  useEffect(() => {
    if (isRegenerating && currentStepOutputCopy) {
      const isDataEqual = isEqual(currentStepOutput, currentStepOutputCopy);
      if (!isDataEqual) {
        setRenderCount((prevCount) => prevCount + 1);
        setIsRegenerating(false);
      }
    }
  }, [currentStepOutput, currentStepOutputCopy, isRegenerating]);

  // This function runs the AI action for the current step
  const sendChatMessage = async (msg) => {
    console.log("SEND CHAT MESSAGE", msg);
    // The stepIndex passed in here determines what AI step is run on the back-end

    const instanceId = (
      Math.random().toString(36) + Math.random().toString(36)
    ).substr(2, 12);

    // Set a timeout to handle long requests
    const timeoutId = setTimeout(() => {
      if (lastInstanceId === instanceId) {
        setLastInstanceId(null);
        setIsFetching(false);
        errorNotification("This request took too long, please try again");
      }
      // Reset any other states if necessary
    }, 20000); // 20 seconds

    const callback = (res) => {
      console.log("CHAT RES", res);

      const nextQuestion = get(res, "next_question", "");
      const informationGathered = get(res, "information_gathered", []);

      if (nextQuestion) {
        console.log("NEXT QUESTION", nextQuestion);
      } else {
        console.log("ALL IS COLLECTED", informationGathered);
      }

      clearTimeout(timeoutId); // Clear the timeout if the request completes in time

      // Save and update the back-end
      // apiRequest.post("/handle_ai_response/", {
      //   content: aiOutput,
      //   step_index: stepIndex,
      //   session_id: sessionId,
      //   step_id: nextStepId,
      // });

      // // If regenerating, don't update the step index because we are not changing steps
      // if (!regeneratePrompt) {
      //   setCurrentStepIndex(stepIndex);
      // }

      // // Determine which step to add data to - if it's regenerating, add it to the current step
      // const stepToUpdate = regeneratePrompt ? activeIndex : stepIndex;

      // setCurrentStepOutput(aiOutput, stepToUpdate);

      // setIsFetching(false);

      // // Reset things
      // setSelectedOptions([]);
      // setShowRegenerateModal(false);
      // setRegeneratePrompt("");
      // setOutputChanges(false);
    };

    setWebsocketRequests([...websocketRequests, { instanceId, callback }]);

    // Either the user is submitting the form, or is hitting 'next' on a step where the subsequent step has no inputs to fill out
    await apiRequest.post("/ai_chat/", {
      thread_id: 123,
      message: msg,
      step_index: activeIndex,

      // A randomly generated unique ID for this specific request
      instance_id: instanceId,
      // The numeric database-based session ID
      session_id: sessionId,
      // A uniquely generated ID for this session, temporary and random, compared to the numeric session ID
      websocket_session_id: websocketSessionId,
    });
  };

  // This function runs the AI action for the current step
  const runAiAction = async (stepIndex) => {
    // The stepIndex passed in here determines what AI step is run on the back-end

    const nextStepId = get(steps, [stepIndex, "id"], null);

    // Get matching selected options
    const matchingSelectedOptions = selectedOptions.map((index) =>
      get(currentStepOutput, index, null)
    );

    setIsFetching(true);

    const finalInput = currentStepInput;
    // matchingSelectedOptions.length > 0 ? matchingSelectedOptions : input;

    if (regeneratePrompt) {
      // Log the current step data before regenerating, so that we can compare after
      setCurrentStepOutputCopy(currentStepOutput);
      setIsRegenerating(true);
    }

    const finalStepData =
      matchingSelectedOptions.length > 0
        ? matchingSelectedOptions
        : currentStepOutput;

    const instanceId = (
      Math.random().toString(36) + Math.random().toString(36)
    ).substr(2, 12);

    // Set a timeout to handle long requests
    const timeoutId = setTimeout(() => {
      if (lastInstanceId === instanceId) {
        setLastInstanceId(null);
        setIsFetching(false);
        errorNotification("This request took too long, please try again");
      }
      // Reset any other states if necessary
    }, 20000); // 20 seconds

    const callback = (res) => {
      // This is the AI generated content for this specific step
      const aiOutput = get(res, "response", {});

      clearTimeout(timeoutId); // Clear the timeout if the request completes in time

      // Save and update the back-end
      apiRequest.post("/handle_ai_response/", {
        content: aiOutput,
        step_index: stepIndex,
        session_id: sessionId,
        step_id: nextStepId,
      });

      // If regenerating, don't update the step index because we are not changing steps
      if (!regeneratePrompt) {
        setCurrentStepIndex(stepIndex);
      }

      // Determine which step to add data to - if it's regenerating, add it to the current step
      const stepToUpdate = regeneratePrompt ? activeIndex : stepIndex;

      setCurrentStepOutput(aiOutput, stepToUpdate);

      setIsFetching(false);

      // Reset things
      setSelectedOptions([]);
      setShowRegenerateModal(false);
      setRegeneratePrompt("");
      setOutputChanges(false);
    };

    setWebsocketRequests([...websocketRequests, { instanceId, callback }]);

    // Either the user is submitting the form, or is hitting 'next' on a step where the subsequent step has no inputs to fill out
    await apiRequest.post("/ai_tool/", {
      user_input: finalInput, // the user input from this specific step
      edited_output: outputChanges ? finalStepData : null, // the content from this specific step
      step_index: stepIndex,
      previous_step_id: get(activeStepConfig, "id"),
      regenerate_prompt: showRegenerateModal ? regeneratePrompt : null,
      selected_options: matchingSelectedOptions,

      // A randomly generated unique ID for this specific request
      instance_id: instanceId,

      // The numeric database-based session ID
      session_id: sessionId,

      // A uniquely generated ID for this session, temporary and random, compared to the numeric session ID
      websocket_session_id: websocketSessionId,
    });
  };

  // Update the session
  const updateSession = async (complete = false) => {
    setIsFetching(true);

    await apiRequest.post("/update_session/", {
      session_id: sessionId,
      step_type: stepType,
      input,
      output: currentStepOutput,
      step_id: get(activeStepConfig, "id"),
      complete,
    });
    setIsFetching(false);

    if (complete) {
      successNotification("Session completed");
      navigate(`/session/${sessionId}`);
    }
  };

  const setCurrentStepOutput = (obj, newIndex = activeIndex) => {
    const stepId = get(steps, [newIndex, "id"], null);
    setOutput({ ...output, [stepId]: obj });
  };

  const setCurrentStepInput = (obj, newIndex = activeIndex) => {
    const stepId = get(steps, [newIndex, "id"], null);
    setInput({ ...input, [stepId]: obj });
  };

  const stepType = get(activeStepConfig, "type", "");

  if (sessionLoadingState === "fetching" || isFetchingTool) {
    return <FullScreenLoader />;
  }

  // 1. EDUCATION STEP
  if (stepType === "education") {
    // Handle the 'back' function conditionally
    let handleBack = null;
    if (activeIndex > 0) {
      handleBack = () => setCurrentStepIndex(activeIndex - 1);
    }

    const isFirstStepText =
      get(activeStepConfig, "education.0.type", "") === "text";

    return (
      <ToolWrapper
        hideTopPadding={isFirstStepText}
        activeIndex={activeIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        handleBack={handleBack}
        previewWidth={previewWidth}
        alignItems="flex-start"
        fullWidth={true}
        buttons={[
          {
            text: "Continue",
            onClick: handleNextStep,
            backgroundColor: primaryColor,
            icon: "FiArrowRight",
            flippedIcon: true,
            size: "large",
          },
        ]}
      >
        <Education
          stepConfig={activeStepConfig}
          previewWidth={previewWidth}
          isAdminEditor={isAdminEditor}
        />
      </ToolWrapper>
    );
  }

  // CHAT STEP
  if (stepType === "chat") {
    // Handle the 'back' function conditionally
    let handleBack = null;
    if (activeIndex > 0) {
      handleBack = () => setCurrentStepIndex(activeIndex - 1);
    }

    let buttons = [
      {
        text: get(activeStepConfig, "buttonText", "Continue"),
        onClick: handleNextStep,
        isFetching,
        size: "large",
        icon: "FiArrowRight",
        flippedIcon: true,
        disabled: !websocketIsOpen,
        backgroundColor: primaryColor,
      },
    ];

    if (true) {
      buttons = [
        {
          text: "Send",
          onClick: () => sendChatMessage(chatMessage),
          size: "large",
          flippedIcon: true,
          icon: "FiArrowRight",
        },
      ];
    }

    const chatQuestion = get(
      activeStepConfig,
      "initial_question",
      "Hey Patrick, can you please describe your business?"
    );

    return (
      <ToolWrapper
        activeIndex={activeIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        handleBack={handleBack}
        previewWidth={previewWidth}
        buttons={buttons}
      >
        <ChatQuestion>{chatQuestion}</ChatQuestion>
        <ChatTextArea
          placeholder="Type your message here..."
          onChange={(e) => setChatMessage(e.target.value)}
        />
      </ToolWrapper>
    );
  }

  // 2. INPUT PHASE
  if (stepType === "input") {
    // Handle the 'back' function conditionally
    let handleBack = null;
    if (activeIndex > 0) {
      handleBack = () => setCurrentStepIndex(activeIndex - 1);
    }

    return (
      <ToolWrapper
        activeIndex={activeIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        handleBack={handleBack}
        previewWidth={previewWidth}
        buttons={[
          {
            text: get(activeStepConfig, "buttonText", "Continue"),
            onClick: handleNextStep,
            isFetching,
            size: "large",
            icon: "FiArrowRight",
            flippedIcon: true,
            disabled: !websocketIsOpen,
            backgroundColor: primaryColor,
          },
        ]}
      >
        <TitleSection
          title={get(activeStepConfig, "label", "Your header text goes here")}
          description={get(activeStepConfig, "description")}
          previewWidth={previewWidth}
          onChange={(key, value) => {
            setActiveStep({ [key]: value });
          }}
        />
        <Input
          previewWidth={previewWidth}
          stepConfig={activeStepConfig}
          input={currentStepInput}
          setInput={setCurrentStepInput}
        />
      </ToolWrapper>
    );
  }

  const isMobile = window.innerWidth < 800;

  // 3. OUTPUT PHASE
  if (stepType === "output") {
    const isLastStep = activeIndex === steps.length - 1;

    const variableButton = isLastStep
      ? {
          text: isMobile ? "Complete" : "View Summary",
          onClick: () => updateSession(true),
          isFetching,
          size: "large",
          icon: "HiClipboard",
          backgroundColor: primaryColor,
        }
      : {
          text: "Next Step",
          onClick: handleNextStep,
          isFetching,
          size: "large",
          width: previewWidth < 800 ? "100%" : "auto",
          icon: "HiArrowRight",
          backgroundColor: primaryColor,
        };

    // Handle the 'back' function conditionally
    let handleBack = null;
    if (activeIndex > 0) {
      handleBack = () => setCurrentStepIndex(activeIndex - 1);
    }

    return (
      <ToolWrapper
        activeIndex={activeIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        handleBack={handleBack}
        maxWidth="800px"
        previewWidth={previewWidth}
        buttons={[
          {
            text: isMobile ? null : "Regenerate",
            type: "basic",
            onClick: () => setShowRegenerateModal(true),
            isFetching,
            size: "large",
            icon: "FiRefreshCcw",
          },
          variableButton,
        ]}
      >
        <TitleSection
          title={get(activeStepConfig, "label", "Your header text goes here")}
          description={get(activeStepConfig, "description")}
          previewWidth={previewWidth}
          onChange={(key, value) => {
            setActiveStep({ [key]: value });
          }}
        />
        {showRegenerateModal && (
          <Modal
            buttons={[
              {
                text: "Regenerate",
                onClick: handleNextStep,
                isFetching,
              },
            ]}
            hide={() => setShowRegenerateModal(false)}
            header={{
              title: "Regenerate Response",
              description:
                "Describe how to improve the response in the next revision",
            }}
          >
            <RegenerateInput
              value={regeneratePrompt}
              onChange={(e) => setRegeneratePrompt(e.target.value)}
              placeholder="Enter your changes here"
            />
          </Modal>
        )}
        <Output
          stepConfig={activeStepConfig}
          output={currentStepOutput}
          setOutput={setCurrentStepOutput}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </ToolWrapper>
    );
  }
};

export default Tool;

const RegenerateInput = styled.textarea`
  width: 100%;
  height: 100px;
  border: 0px;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  background: var(--card-background);
  color: var(--text-color);
  outline: none;
`;

const ChatTextArea = styled(TextareaAutosize)`
  width: 100%;
  padding: 0 0 10px 0;
  font-size: 25px;
  font-weight: 400;
  color: var(--text-color);
  background: transparent;
  border: 0px;
  border-bottom: 1px solid var(--divider);
  resize: none;
  outline: none;
`;

const ChatQuestion = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 60px;
  margin-top: 20px;
  text-align: center;
`;
