import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Icon } from "app/components";
import { get } from "lodash";
import { rUser } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const AuthSidebar = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const pathname = useLocation().pathname;

  const user = useRecoilValue(rUser);

  return (
    <Container>
      <SidebarItem
        label="Browse"
        icon="FiSearch"
        active={tab === "browse" || (!tab && !pathname.includes("/history"))}
        onClick={() => navigate("/tools/browse")}
      />
      <SidebarItem
        label="History"
        icon="FiClock"
        active={pathname.includes("/history")}
        onClick={() => navigate("/history")}
      />
      {/* <SidebarItem
        label="Favorites"
        icon="FiStar"
        active={tab === "favorites"}
        onClick={() => navigate("/tools/favorites")}
      /> */}
      {get(user, "email").includes("scaale") && (
        <SidebarItem
          label="My Tools"
          icon="FiGrid"
          active={tab === "my-tools"}
          onClick={() => navigate("/tools/my-tools")}
        />
      )}
    </Container>
  );
};

const SidebarItem = ({ label, icon, active, onClick }) => {
  return (
    <ItemContainer active={active} onClick={onClick}>
      <Icon data={{ icon, color: "var(--text-color)", size: 22 }} />
      <ItemLabel>{label}</ItemLabel>
    </ItemContainer>
  );
};

export default AuthSidebar;

const ItemLabel = styled.div`
  font-size: 14px;
  color: var(--text-color);
`;

const ItemContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.active ? "var(--card-background)" : "transparent"};
  cursor: pointer;
  &:hover {
    background-color: var(--card-background);
  }
`;

const Container = styled.div`
  min-width: 212px;
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 1000px) {
    display: none;
  }
`;
