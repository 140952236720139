import { Button, FullScreenLoader, Row, Tabs, Text } from "app/components";
import { get, startCase } from "lodash";
import {
  rActiveStepIndex,
  rConfirmationModalData,
  rFetchingUserComplete,
  rSearch,
  rToolsList,
  rUser,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AuthSidebar from "./AuthSidebar";
import CardGrid from "../components/CardGrid";
import { EmptyStateCard } from "app/components/EmptyStateCard";
import PublicWrapper from "app/public/PublicWrapper";
import { apiRequest } from "app/utils/apiRequests";
import { safeLower } from "app/utils/utils";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";

const Browse = ({ itemsPerPage = 12 }) => {
  const [tools, setTools] = useRecoilState(rToolsList);
  const search = useRecoilValue(rSearch);
  const navigate = useNavigate();
  const [activeTags, setActiveTags] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const user = useRecoilValue(rUser);
  const { tab } = useParams();

  const setActiveStepIndex = useSetRecoilState(rActiveStepIndex);

  const [publishedTab, setPublishedTab] = useState("all");

  // Sample tags array
  const tags = [
    "All",
    "Business",
    "Marketing",
    "Sales",
    "Go To Market",
    "Product",
    "Design",
    "Engineering",
    "Start With Why",
    "Leadership",
    "Startup",
    "YCombinator",
    "Sales",
    "Product Management",
  ];

  const fetchingUserComplete = useRecoilValue(rFetchingUserComplete);

  // Fetch tools
  useEffect(() => {
    setActiveStepIndex(0);
    if (fetchingUserComplete) {
      setIsFetching(true);
      const base = user ? "tools" : "tools_anonymous";
      let endpoint = `/${base}/?tab=${tab || "browse"}`;

      const tagsString = activeTags.join(",");
      if (tagsString) {
        endpoint += `&tags=${tagsString}`;
      }

      apiRequest.get(endpoint).then((response) => {
        setTools(get(response, "data"));
        setIsFetching(false);
      });
    }
  }, [tab, activeTags, fetchingUserComplete]);

  const filteredTools = tools
    .filter(
      (p) => search === "" || safeLower(p.name).includes(safeLower(search))
    )
    .filter((p) => {
      if (publishedTab === "published") {
        return p.published;
      } else if (publishedTab === "unpublished") {
        return !p.published;
      }
      return true;
    });

  const [isCreating, setIsCreating] = useState(false);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const deleteTool = (pageId) => {
    const newTools = tools.filter((p) => p.id !== pageId);
    setTools(newTools);
    successNotification("Tool Deleted");
    apiRequest.delete("/tool/", {
      data: { id: pageId },
    });
  };

  const createTool = () => {
    setIsCreating(true);
    apiRequest.post("/create_tool/").then((response) => {
      const data = get(response, "data");
      setTools([...tools, data]);
      navigate(`/admin/edit_tool/${data.id}`);
      setIsCreating(false);
    });
  };

  const getOptions = (p) => [
    {
      label: "Duplicate Tool",
      icon: "FiCopy",
      onClick: () => {
        apiRequest.post("/duplicate_tool/", { id: p.id }).then((r) => {
          const newTool = get(r, "data");
          setTools([newTool, ...tools]);
          successNotification("Tool Duplicated");
        });
      },
    },
    {
      label: "Delete",
      icon: "FiTrash",
      onClick: () =>
        setConfirmationModalData({
          title: "Delete Tool",
          text: "Are you sure you want to delete this tool? This is irreversible.",
          confirm: () => deleteTool(p.id),
        }),
    },
  ];

  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <PublicWrapper>
      <ContentRow>
        {user && <AuthSidebar />}
        <MainContent>
          {["favorites", "my-tools"].includes(tab) && (
            <Row justifyContent="space-between" margin="0 0 25px 0">
              <Text
                data={{
                  text: startCase(tab),
                  fontSize: 24,
                  fontWeight: 500,
                }}
              />
            </Row>
          )}
          {tab === "my-tools" && (
            <Row justifyContent="space-between" margin="0 0 25px 0">
              <Tabs
                data={{
                  margin: "0 0 20px 0",
                  tabs: [
                    {
                      label: "All",
                      active: publishedTab === "all",
                      onClick: () => setPublishedTab("all"),
                    },
                    {
                      label: "Published",
                      active: publishedTab === "published",
                      onClick: () => setPublishedTab("published"),
                    },
                    {
                      label: "Unpublished",
                      active: publishedTab === "unpublished",
                      onClick: () => setPublishedTab("unpublished"),
                    },
                  ],
                }}
              />
              {tab === "my-tools" && (
                <Button
                  data={{
                    text: "Add Tool",
                    icon: "FiPlus",
                    onClick: createTool,
                    isFetching: isCreating,
                  }}
                />
              )}
            </Row>
          )}
          {(tab === "browse" || !tab) && (
            <Tags>
              {tags.map((t, index) => (
                <Tag
                  key={index}
                  active={activeTags.includes(t)}
                  onClick={() => {
                    if (activeTags.includes(t)) {
                      setActiveTags(activeTags.filter((tag) => tag !== t));
                    } else {
                      setActiveTags([...activeTags, t]);
                    }
                  }}
                >
                  {t}
                </Tag>
              ))}
            </Tags>
          )}
          {tools.length > 0 && (
            <CardGrid
              hasImage
              noResultsName={"tools"}
              items={filteredTools.map((t) => {
                let description = `Used ${get(t, "session_count", 0)} times`;
                let route = `/tool/details/${t.id}`;
                // let route = `/tool/${t.id}`;
                let options = [];

                if (tab === "my-tools") {
                  description = "Click to edit";
                  route = `/admin/edit_tool/${t.id}`;
                  options = getOptions(t);
                }

                return {
                  title: t.name,
                  description,
                  image: get(t, "image", null),
                  onClick: () => navigate(route),
                  options,
                };
              })}
              itemsPerPage={itemsPerPage}
            />
          )}

          {tools.length === 0 && <RenderEmpty tab={tab} />}
        </MainContent>
      </ContentRow>
    </PublicWrapper>
  );
};

const RenderEmpty = ({ tab }) => {
  const navigate = useNavigate();

  let title = "No tools found";
  let subtitle = "Try searching for something else";
  let buttonText = "Browse Tools";
  let onClick = () => navigate("/browse");

  if (tab === "favorites") {
    title = "No favorites found";
    subtitle = "Add some tools to your favorites to see them here";
    buttonText = "Browse Tools";
    onClick = () => navigate("/browse");
  }

  return (
    <EmptyStateCard
      title={title}
      subtitle={subtitle}
      buttonText={buttonText}
      onClick={onClick}
      icon={"FiSearch"}
    />
  );
};

export const MainContent = styled.div`
  flex: 1;
  overflow-x: hidden;
`;

export const ContentRow = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
`;

// Styled components for Tags and Tag
const Tags = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  margin: 0 0 30px 0;
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
`;

const Tag = styled.div`
  background-color: #333;
  color: #fff;

  ${(p) =>
    p.active &&
    `
    background-color: #f1f1f1;
    color: #0F0F0F;
  `}

  border-radius: 20px;
  padding: 8px 16px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: #444;
  }
`;

export default Browse;
