import { Breadcrumb, FullScreenLoader, Spinner } from "app/components";
import { ContentRow, MainContent } from "./Browse";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AuthSidebar from "./AuthSidebar";
import CardGrid from "app/adminApp/components/CardGrid";
import { EmptyStateCard } from "app/components/EmptyStateCard";
import PublicWrapper from "app/public/PublicWrapper";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { rUser } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import { useRecoilValue } from "recoil";

const History = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [tool, setTool] = useState(null);

  const user = useRecoilValue(rUser);

  const { toolId } = useParams();

  useEffect(() => {
    setIsFetching(true);
    apiRequest
      .get(toolId ? `/sessions/?tool_id=${toolId}` : "/sessions/")
      .then((res) => {
        setSessions(get(res, ["data", "sessions"], []));
        setTool(get(res, ["data", "tool"], null));
        setIsFetching(false);
      });
  }, [toolId]);

  const duplicateSession = (sessionId) => {
    apiRequest
      .post(`/duplicate_session/`, {
        id: sessionId,
      })
      .then((res) => {
        const newSession = get(res, "data", null);
        setSessions([newSession, ...sessions]);
        successNotification("Session duplicated");
      });
  };

  const deleteSession = (sessionId) => {
    apiRequest.delete(`/sessions/`, { data: { id: sessionId } }).then(() => {
      setSessions(sessions.filter((s) => s.id !== sessionId));
      successNotification("Session deleted");
    });
  };

  // TODO - Add 'skeleton loader' while fetching
  if (isFetching) {
    return <FullScreenLoader />;
  }

  let crumbs = [
    {
      text: "History",
    },
  ];

  if (toolId) {
    crumbs = [
      {
        text: "History",
        onClick: () => navigate("/history"),
      },
      {
        text: get(tool, "name", "Tool"),
      },
    ];
  }

  return (
    <PublicWrapper>
      <ContentRow>
        {user && <AuthSidebar />}
        <MainContent>
          <Breadcrumb
            items={crumbs}
            fontStyle="headingLg"
            margin="0px 0px 30px 0px"
          />
          {isFetching ? (
            <Spinner color="var(--text-color)" />
          ) : (
            <>
              {sessions.length === 0 && (
                <EmptyStateCard
                  title="No history found"
                  description="Start using tools to see your history here"
                />
              )}
              <CardGrid
                hasImage
                items={sessions.map((s) => {
                  const recentSession = get(s, "recent_session", {});
                  const progressPercent = get(
                    recentSession,
                    "progress_percent",
                    0
                  );

                  return {
                    title: s.name,
                    image: s.image,
                    noResultsName: "sessions",
                    progress_percent: progressPercent,
                    description: `${progressPercent}% complete`,
                    options: [
                      {
                        label: "Duplicate Session",
                        icon: "FiCopy",
                        onClick: () => duplicateSession(s.id),
                      },
                      {
                        label: "Delete Session",
                        icon: "FiTrash",
                        onClick: () => deleteSession(s.id),
                      },
                    ],
                    onClick: () => {
                      if (toolId) {
                        if (s.complete) {
                          navigate(`/session/${s.uuid}`);
                        } else {
                          navigate(`/tool/${s.tool_id}/?sessionId=${s.uuid}`);
                        }
                      } else {
                        navigate(`/history/${s.id}`);
                      }
                    },
                  };
                })}
                itemsPerPage={12}
              />
            </>
          )}
        </MainContent>
      </ContentRow>
    </PublicWrapper>
  );
};

export default History;
