import { atom, selector } from "recoil";

import { get as lodashGet } from "lodash";

export const rInput = atom({
  key: "input",
  default: {},
});

export const rOutput = atom({
  key: "output",
  default: {},
});

export const rIsFetchingTool = atom({
  key: "isFetchingTool",
  default: false,
});

export const rOutputStepChanges = atom({
  key: "outputStepChanges",
  default: false,
});

export const rIsFetchingUser = atom({
  key: "isFetchingUser",
  default: false,
});

export const rFetchingUserComplete = atom({
  key: "fetchingUserComplete",
  default: false,
});

export const rToolsList = atom({
  key: "toolsList",
  default: [],
});

export const rTools = atom({
  key: "tools",
  default: {},
});

export const rSearch = atom({
  key: "search",
  default: "",
});

export const rStepTab = atom({
  key: "stepTab",
  default: "basics",
});

export const rRenderCount = atom({
  key: "renderCount",
  default: 0,
});

export const rActiveStepIndex = atom({
  key: "activeStepIndex",
  default: null,
});

export const rUniqueValuesMap = atom({
  key: "uniqueValuesMap",
  default: {},
});

export const rIsFetchingLiveData = atom({
  key: "isFetchingLiveData",
  default: false,
});

export const rPagination = atom({
  key: "pagination",
  default: {},
});

export const rScreenPreviewSize = atom({
  key: "screenPreviewSize",
  default: "DESKTOP",
});

export const rToolEditorTab = atom({
  key: "patternEditorTab",
  default: null,
});

export const rFormSectionIndex = atom({
  key: "formSectionIndex",
  default: null,
});

export const rFormState = atom({
  key: "formState",
  default: {},
});

export const rFormValidation = atom({
  key: "formValidation",
  default: {},
});

export const rUser = atom({
  key: "user",
  default: null,
});

export const rIsFetchingConfig = atom({
  key: "isFetchingConfig",
  default: false,
});

export const rToolChanges = atom({
  key: "toolChanges",
  default: false,
});

export const rActiveEditField = atom({
  key: "activeEditField",
  default: null,
});

export const rFilters = atom({
  key: "filters",
  default: {},
});

export const rConfirmationModalData = atom({
  key: "confirmationModalData",
  default: null,
});

export const rActiveApp = atom({
  key: "activeApp",
  default: {},
});

export const rFormIsFetching = atom({
  key: "formisFetching",
  default: null,
});

export const rWebsocketSessionId = atom({
  key: "websocketSessionId",
  default: null,
});

export const rWebsocket = atom({
  key: "websocket",
  default: null,
});

export const rWebsocketRequests = atom({
  key: "websocketRequests",
  default: [],
});

export const rOrganization = atom({
  key: "organization",
  default: {},
});

export const rSetOrganization = selector({
  key: "setOrganizationSelector",
  get: ({ get }) => {
    return get(rOrganization);
  },
  set: ({ set, get }, newObj) => {
    const current = get(rOrganization);
    set(rOrganization, { ...current, ...newObj });
  },
});

export const rApps = atom({
  key: "apps",
  default: [],
});

// ALL ATOMS ABOVE HERE
export const rTranslations = selector({
  key: "translations",
  get: ({ get }) => {
    const activeApp = get(rActiveApp);
    return lodashGet(activeApp, "translations", {});
  },
});

// ALL ATOMS ABOVE HERE
export const rTheme = selector({
  key: "theme",
  get: ({ get }) => {
    const activeApp = get(rActiveApp);
    return lodashGet(activeApp, "theme", "dark");
  },
});

// ALL BASIC SELECTORS ABOVE HERE
export const userStateSelector = selector({
  key: "userStateSelector",
  get: ({ get }) => {
    return get(rUser);
  },
  set: ({ set, get }, newValue) => {
    const currentUser = get(rUser);
    set(rUser, { ...currentUser, ...newValue });
  },
});

export const rApp = selector({
  key: "app",
  get: ({ get }) => {
    return get(rActiveApp) || {};
  },
  set: ({ set, get }, newObj) => {
    const currentApp = get(rActiveApp) || {};
    const updatedApp = { ...currentApp, ...newObj };
    set(rActiveApp, updatedApp);
  },
});

export const rAppDateFormat = selector({
  key: "appDateFormat",
  get: ({ get }) => {
    const activeApp = get(rActiveApp) || {};

    // DOUBLE CHECK THIS
    const defaultDateFormatObject = {
      date: "YYYY/MM/DD",
      time: null,
      showAmPm: false,
    };

    const dateFormat = activeApp.date_format || {};

    // Combine default object with user's app date format
    const appDateFormat = {
      ...defaultDateFormatObject,
      ...dateFormat,
    };

    return appDateFormat;
  },
});

export const rSubscription = selector({
  key: "subscription",
  get: ({ get }) => {
    const organization = get(rOrganization);
    return lodashGet(organization, "subscription", {});
  },
});

// SET EVERYTHING
export const rSetConfig = selector({
  key: "setConfig",
  get: ({ get }) => {},
  set: ({ set }, data) => {
    set(rApp, lodashGet(data, "app", {}));
    set(rTools, lodashGet(data, "tools", []));
    set(rOrganization, lodashGet(data, "organization", {}));
    set(rApps, lodashGet(data, "apps", []));
    const responseUser = lodashGet(data, "user");
    if (responseUser) {
      set(rUser, responseUser);
    }
  },
});

// CLEAR EVERYTHING
export const rClearConfig = selector({
  key: "clearConfig",
  get: ({ get }) => {},
  set: ({ set }) => {
    set(rApp, {});
    set(rActiveApp, {});
    set(rTools, []);
    set(rOrganization, {});
    set(rApps, []);
    set(rUser, {});
  },
});
