import { Button, Icon, Row, Text } from "app/components";
import { get, startCase } from "lodash";
import { rApp, rScreenPreviewSize, rToolChanges } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import BackSection from "app/components/BackSection";
import Cookies from "js-cookie";
import EditableText from "app/adminApp/components/EditableText";
import { apiRequest } from "app/utils/apiRequests";
import mixpanel from "mixpanel-browser";
import { openToolInNewTab } from "app/utils/utils";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";
import useTool from "app/utils/useTool";

const ToolEditorHeader = () => {
  const { tool, setTool } = useTool();

  const [screenPreviewSize, setScreenPreviewSize] =
    useRecoilState(rScreenPreviewSize);

  const [isSaving, setIsSaving] = useState(false);

  const [toolChanges, setToolChanges] = useRecoilState(rToolChanges);

  // Save pattern to API
  const savePage = (preview) => {
    setIsSaving(true);
    apiRequest.post("/tool/", tool).then(() => {
      setToolChanges(false);
      setIsSaving(false);
      successNotification("Saved");
      if (preview) {
        // mixpanel.track("Preview Pattern");
        openToolInNewTab({
          toolId: get(tool, "id"),
          token: Cookies.get("accessToken"),
        });
      } else {
        mixpanel.track("Save Page");
      }
    });
  };

  return (
    <Container>
      <Row style={{ height: "100%" }} alignItems="center">
        <BackSection />
        <EditableText
          editable={true}
          fontSize={22}
          fontWeight={500}
          value={get(tool, "name", "")}
          onChange={(v) => setTool({ name: v })}
        />
      </Row>

      <HeaderTabs
        setScreenPreviewSize={setScreenPreviewSize}
        screenPreviewSize={screenPreviewSize}
      />

      <Row
        alignItems="center"
        justifyContent="flex-end"
        gap="10px"
        margin="0 20px 0 0"
      >
        <Button
          data={{
            onClick: () => savePage(true),
            text: "View Live Page",
            isFetching: isSaving,
            type: "basic",
            icon: "FiExternalLink",
          }}
        />
        <Button
          data={{
            disabled: !toolChanges,
            onClick: () => savePage(false),
            text: toolChanges ? "Save Changes" : "Changes Saved",
            isFetching: isSaving,
          }}
        />
      </Row>
    </Container>
  );
};

export default ToolEditorHeader;

const Container = styled.div`
  background: var(--card-background);
  border-bottom: 1px solid var(--divider);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
`;

// Navigation Header tabs

const NavTabs = styled.div`
  display: flex;
  gap: 10px;
`;

const NavTab = styled.div`
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  text-align: center;
  cursor: pointer;
  padding: 8px 15px 8px 15px;
  background: ${(p) => (p.active ? "var(--background)" : "transparent")};
  &:hover {
    background: var(--background);
  }
`;

const HeaderTabs = ({ setScreenPreviewSize, screenPreviewSize }) => {
  const tabs = [
    {
      label: "Desktop",
      value: "DESKTOP",
      onClick: () => setScreenPreviewSize("DESKTOP"),
      icon: "FiMonitor",
    },
    {
      label: "Tablet",
      value: "TABLET",
      onClick: () => setScreenPreviewSize("TABLET"),
      icon: "FiTablet",
    },
    {
      label: "Phone",
      value: "MOBILE",
      onClick: () => setScreenPreviewSize("MOBILE"),
      icon: "FiSmartphone",
    },
  ];

  return (
    <NavTabs>
      {tabs.map((t, index) => {
        const active = t.value === screenPreviewSize;

        return (
          <NavTab
            key={index}
            active={active}
            onClick={() => setScreenPreviewSize(t.value)}
          >
            <Icon
              data={{
                icon: t.icon,
                size: 20,
                color: active ? "var(--dark-grey)" : "var(--dark-grey)",
                hover: true,
              }}
            />
            <Text
              data={{
                text: t.label ? t.label : startCase(t.value),
                color: active ? "var(--text-color)" : "var(--dark-grey)",
                fontSize: 16,
                fontWeight: 500,
                cursor: "pointer",
              }}
            />
          </NavTab>
        );
      })}
    </NavTabs>
  );
};
